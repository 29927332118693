@import "./color.scss";
@import "./font.scss";
@import "./mediaQuery.scss";

@mixin box($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-center-column {
  @include flex-center;
  flex-direction: column;
}

@mixin flex-center-vert {
  display: flex;
  align-items: center;
}

@mixin flex-center-horiz {
  display: flex;
  justify-content: center;
}

@mixin cover-background {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.antd-btn-override {
  background-color: $btn-bg-color;
  border: none;
  border-radius: 5px;
  text-transform: capitalize;
  @include fontStyle(14px, 600, "OpenSansSemiBold");
  &:hover,
  &:focus {
    background-color: $btn-hover;
    color: $btn-bg-color;
    border: 1px solid $btn-bg-color;
  }
}

.ant-switch-checked{
  background-color: $component-btn-clr
}

.antd-action-btn-override {
  @extend .antd-btn-override;
  background-color: $component-btn-clr;
  border: 1px solid $component-btn-clr;
  color: $white-color;
  &:hover,
  &:focus {
    background-color: $btn-hover;
    color: $component-btn-clr;
    border: 1px solid $component-btn-clr;
    .button-svg-icon {
      color: $component-btn-clr;
    }
  }
  .button-svg-icon {
    font-size: 14px;
    color: $white-color;
    &:hover {
      color: $component-btn-clr;
    }
  }
}

.antd-white-btn-override {
  font-family: "OpenSans";
  background-color: $white-color;
  margin-right: 20px;
  border-radius: 3px;
  @include fontSize(12);
  border: 1px solid $light-grey;
  color: $dark-black;
  &:hover,
  &:active,
  &:focus {
    background-color: $component-btn-clr;
    color: $white-color;
    border: 1px solid $component-btn-clr;
  }
}

.search-box-override {
  border-radius: 20px;
  .ant-input {
    margin-right: 20px;
  }
  .ant-input-affix-wrapper {
    border-radius: 4px;
    .ant-input-suffix {
      left: -20px;
      position: relative;
    }
  }

  .ant-input-group-addon {
    left: -31px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background: transparent;
    box-shadow: none;
    :last-child {
      border-radius: 50%;
    }
    button {
      border-radius: 50%;
      background: transparent;
      color: $svg-color;
      border: $white-color;
      box-shadow: none;
      height: 30px;
      width: 30px;
      span {
        left: -5px;
        position: relative;
      }
      &:hover {
        background: $light-grey-color;
        color: $black-color;
        border-radius: 50%;
        border-color: $light-grey-color;
      }
    }
  }
}
.table-svg-action-icon {
  font-size: 18px;
  cursor: pointer;
  color: $table-svg-action-icon;
  &:hover {
    color: $table-svg-action-icon-hover;
  }
}

.input-box-css {
  height: 36px;
  background: $input-bg-color;
  border-color: $input-bg-color;
  color: $black-color;
  border-radius: 3px;
  &:focus,
  &:hover,
  &:visited {
    background: $white-color;
    input {
      background: $white-color;
    }
  }
  &:hover {
    border-color: $component-btn-clr;
  }
}
.drs-input-box-style {
  .ant-form-item-label {
    font-family: "OpenSans";
  }
  .ant-form-item-control-input-content {
    input,
    textarea,
    .ant-select-selector {
      @extend .input-box-css;
    }
  }
}

.plain-cancel-button {
  background-color: $white-color;
  color: $cancel-btn-clr;
  border-color: $cancel-btn-brd;
}
