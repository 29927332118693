@import "./color.scss";
@import "./font.scss";
@import "./mediaQuery.scss";
@import "./style.scss";

$font-main: "InterRegular";

body {
  font-family: $font-main;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
